import PropTypes from 'prop-types';
import api from '../../util/api';

export const REQUEST_STATUSES = {
  unassigned: 'unassigned',
  'out-to-bid': 'out-to-bid',
  assigned: 'assigned',
  started: 'started',
  completed: 'completed',
  cancelled: 'cancelled',
  canceled: 'canceled',
  active: 'active',
  lost: 'lost',
  replacement: 'replacement',
  stolen: 'stolen',
};

export const FINISHED_STATUSES = [
  REQUEST_STATUSES.completed,
  REQUEST_STATUSES.cancelled,
];

export const ServiceRequestShape = {
  assetNo: PropTypes.string,
  assetVehicleBodyType: PropTypes.string,
  assetVehicleType: PropTypes.string,
  assignedAt: PropTypes.string,
  createdAt: PropTypes.string,
  dueAt: PropTypes.string,
  hasNotes: PropTypes.bool,
  id: PropTypes.string,
  requestId: PropTypes.string,
  requestItems: PropTypes.arrayOf(PropTypes.string),
  requestType: PropTypes.string,
  requestTypeName: PropTypes.string,
  service: PropTypes.string,
  serviceName: PropTypes.string,
  status: PropTypes.string,
  statusChangeDate: PropTypes.string,
  updatedAt: PropTypes.string,
  watched: PropTypes.bool,
  assetMake: PropTypes.string,
  assetModel: PropTypes.string,
  assetYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const WatchedServiceRequestShape = PropTypes.shape({
  assetMake: PropTypes.string.isRequired,
  assetModel: PropTypes.string.isRequired,
  assetYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  createdBy: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  hasNotes: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  updatedBy: PropTypes.string.isRequired,
  watched: PropTypes.bool.isRequired,
});

export const RequestItemShape = PropTypes.shape({
  assetNo: PropTypes.string,
  status: PropTypes.string,
  assetMake: PropTypes.string,
  assetModel: PropTypes.string,
  assetYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  assetVin: PropTypes.string,
  assetTagNo: PropTypes.string,
  service: PropTypes.string,
  serviceName: PropTypes.string,
  requestId: PropTypes.string,
  assetVehicleBodyType: PropTypes.string,
  assetVehicleType: PropTypes.string,
});

export const RequestMaintenanceDetailsShape = PropTypes.shape({
  ataCode: PropTypes.string,
  ataDesc: PropTypes.string,
  cause: PropTypes.string,
  complaint: PropTypes.string,
  correction: PropTypes.string,
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  quantity: PropTypes.string,
});

export const RequestMaintenanceShape = PropTypes.shape({
  id: PropTypes.string,
  odometer: PropTypes.number,
  repairDate: PropTypes.string,
  serviceId: PropTypes.string,
  totalCost: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  vendorId: PropTypes.string,
  maintenanceRepairDetails: PropTypes.arrayOf(RequestMaintenanceDetailsShape),
});

export const getServiceRequests = config => api.get('/requests', config);

export const getServiceRequestsCTAs = config =>
  api.get('/requests?isCTAOnly=true', config);

export const createServiceRequest = payload => api.post(`/requests`, payload);

export const getServiceRequestDetails = (id, config) =>
  api.get(`/requests/${id}`, config);

export const getAllTransitions = () => api.get(`/requests/transitions`);

export const updateServiceRequest = (id, data) =>
  api.patch(`/requests/${id}`, data);

export const saveServiceRequestNote = (requestId, note) =>
  api.post(`/requests/${requestId}/notes`, {
    note,
  });

export const updateWatchServiceRequest = (id, data) =>
  api.patch(`/requests/${id}/watch`, data);

/**
 * Transition to RepairHeld status and add a comment for APC.
 */
export const holdRepair = (id, note) =>
  api.post(`/requests/${id}/hold-repair`, { note });

/**
 * Transition to HelpMe status and add a comment for APC.
 */
export const helpMe = (id, note) =>
  api.post(`/requests/${id}/help-me`, { note });

export const noteShape = PropTypes.shape({
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  createdByName: PropTypes.string,
  id: PropTypes.string,
  note: PropTypes.string,
});

export const notesShape = {
  requestNotes: PropTypes.arrayOf(noteShape),
  requestId: PropTypes.string,
};

export const updateVendor = (requestId, vendorId) =>
  api.patch(`/requests/${requestId}`, { vendorId });
