import customerService from './customer-service.json';
import assets from './assets.json';
import common from './common.json';
import dashboard from './dashboard.json';
import main from './main.json';
import map from './map.json';
import marketplace from './marketplace.json';
import validator from './validator.json';
import vehicles from './vehicles.json';
import drivertraining from './drivertraining.json';
import checkout from './checkout.json';
import services from './services.json';
import browse from './browse.json';
import pageeditor from './pageeditor.json';
import contentmanagement from './contentmanagement.json';
import chooseFundingMethodDialog from './choose-funding-method-dialog.json';
import orderListItem from './order-list-item.json';
import vendor from './vendor.json';
import maintenanceRepair from './maintenance-repair.json';
import pm from './preventive-maintenance.json';
import serviceRequests from './service-requests.json';
import telemetry from './telemetry.json';
import stock from './stock.json';
import credit from './credit.json';
import fuel from './fuel.json';
import shopvehicles from './shopvehicles.json';
import consolidatedBilling from './consolidated-billing.json';
import selectorAcquisition from './selector-acquisition.json';
import locationsHierarchy from './location-hierarchy.json';
import remarketing from './remarketing.json';
import parentOrg from './parent-org.json';
import telematics from './telematics.json';
import dealerPortal from './dealer-portal.json';
import users from './users.json';
import individualAsset from './individual-asset.json';
import roboticAsset from './robotic-asset.json';

export default {
  assets,
  common,
  customerService,
  dashboard,
  main,
  map,
  marketplace,
  validator,
  vehicles,
  drivertraining,
  vendor,
  checkout,
  services,
  browse,
  pageeditor,
  contentmanagement,
  chooseFundingMethodDialog,
  orderListItem,
  maintenanceRepair,
  pm,
  serviceRequests,
  telemetry,
  stock,
  credit,
  fuel,
  shopvehicles,
  consolidatedBilling,
  selectorAcquisition,
  locationsHierarchy,
  remarketing,
  parentOrg,
  telematics,
  dealerPortal,
  users,
  individualAsset,
  roboticAsset,
};
