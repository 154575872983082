/* eslint-disable prefer-destructuring */

/**
 * @fileoverview This file exists to standardize usage of environment variables throughout the
 * application. When an environment variables is needed, it should be imported through this file.
 * This coolocation allows us to monitor and and document environment variables, as well as provides
 * consistent default setting, simplifies validation, and is easier to mock during testing.
 */

/**
 * @param {development|production} ENV - The environment the application is running in.
 *  ENVIRONMENT is used by the mobile app to set environment as there's no way to overwrite the 'test' environment
 */
export const ENV =
  process.env.ENVIRONMENT || process.env.NODE_ENV || 'development';

/**
 * @param {string} [IDLE_TIME_MINUTES] - The number of minutes before a unser is considered inactive.
 */
export const IDLE_TIME_MINUTES = process.env.IDLE_TIME_MINUTES;

/**
 * @param {string} [LOGOUT_COUNTDOWN_SECONDS] - The number of seconds a user has to respond to an inactivity prompt.
 */
export const LOGOUT_COUNTDOWN_SECONDS = process.env.LOGOUT_COUNTDOWN_SECONDS;

/**
 * @param {string} PLAID_KEY - The API key provided by Plaid, specific to the Plaid API environment (below)
 */
export const PLAID_KEY = process.env.PLAID_KEY;

/**
 * @param {sandbox|development|production} PLAID_ENV - Defines which Plaid API to use for account verification.
 */
export const PLAID_ENV = process.env.PLAID_ENV;

/**
 * @param {string} STRIPE_KEY - The API key provided by Stripe used to generate Stripe tokens and sources.
 */
export const STRIPE_KEY = process.env.STRIPE_KEY;

/**
 * @param {string} EXPERIAN_URL - The URL provided by Experian used for credit applications for Stock Acquisition.
 */
export const EXPERIAN_URL = process.env.EXPERIAN_URL;

/**
 * @param {string} BASE_URL
 */
export const BASE_URL = (process.env.BASE_URL || '').trim(); // Since value in SSM param store is required, space char

/**
 * @param {string} [SENTRY_DSN] - A Sentry DSN (URL) used to report errors.
 */
export const SENTRY_DSN = process.env.SENTRY_DSN;

/**
 * @param {local|development|staging|production} [HOST_ENV] - The environment used for reporting to Sentry.
 */
export const HOST_ENV = process.env.HOST_ENV;

/**
 * @param {string} - The applications current version, as generated by Heroku during deployment.
 */
export const HEROKU_RELEASE_VERSION = process.env.HEROKU_RELEASE_VERSION;
// eslint-disable-next-line no-console
if (HEROKU_RELEASE_VERSION) console.log('V', HEROKU_RELEASE_VERSION);

/**
 * @param {string} [ENABLE_REDUX_LOGGER] - Set true to enable Redux logging (lots of logs! Be aware)
 */
export const ENABLE_REDUX_LOGGER = process.env.ENABLE_REDUX_LOGGER;

/**
 * @param {string} [ENABLE_REDUX_DEVTOOLS] - Set to true to enable Redux Development Tools.
 */
export const ENABLE_REDUX_DEVTOOLS = process.env.ENABLE_REDUX_DEVTOOLS;

/**
 * @param {string} [GOOGLE_API_KEY_WEB] - A Google API key which is restrited for use
 *  by domains matching the pattern `*.holmanfm.com/*`.
 *  This variable shall be undefined on Mobile builds by design.
 */
export const GOOGLE_API_KEY_WEB = process.env.GOOGLE_API_KEY_WEB;

/**
 * @param {string} [GOOGLE_API_KEY_ANDROID] - A Google API key which is restricted for use by Android clients only.
 *  This variable shall be undefined on Web builds by design.
 *  This variable shall be defined on both Android and iOS clients, but must not be used by iOS clients.
 */
export const GOOGLE_API_KEY_ANDROID = process.env.GOOGLE_API_KEY_ANDROID;

/**
 * @param {string} [GOOGLE_API_KEY_IOS] - A Google API key which is restricted for use by iOS clients only.
 *  This variable shall be undefined on Web builds by design.
 *  This variable shall be defined on both Android and iOS clients, but must not be used by Android clients.
 */
export const GOOGLE_API_KEY_IOS = process.env.GOOGLE_API_KEY_IOS;

export const GA_KEY = process.env.GA_KEY;

export const GOOGLE_MAP_ID = process.env.GOOGLE_MAP_ID;

/**
 * Requested google tracking ID from Marketing
 */
export const GA_KEY_MARKETING = process.env.GA_KEY_MARKETING;

if (!ENV) {
  throw new Error(`NODE_ENV has not been set.`);
}

export const isProduction = ENV === 'production';

export const isDevelopment = !isProduction;
